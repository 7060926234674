<template>
  <div class="chart-wrapper">
    <div class="chart-opt">
      <span>{{ title }}</span>
    </div>
    <div class="d-flex h-500">
      <!-- <div v-show="loading" class="qt-block-ui" /> -->
      <div v-show="loading" class="loading min-h-300">
        <LoadingAnim />
      </div>
      <div v-show="!loading" class="chart chart-center">
        <LottieAnimNoData v-show="this.data === 'none'" />
        <div v-show="this.data !== 'none'">
          <apexchart
            class="bar-chart"
            ref="barChart"
            height="500"
            :options="barChart"
            :series="series_bar"
          ></apexchart>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';
import LottieAnimNoData from '@/components/rebranding/animLottie/NoData.vue';

export default {
  name: 'Chart',
  components: {
    LoadingAnim,
    LottieAnimNoData,
  },
  props: {
    format: {
      type: String,
    },
    title: {
      type: String,
    },
    data: {
      type: [Array, String],
    },
    loading: {
      type: Boolean,
    },
    options: {
      type: Boolean,
    },
  },
  data() {
    const colors = [
      '#F3B415',
      '#F27036',
      '#663F59',
      '#6A6E94',
      '#4E88B4',
      '#00A7C6',
      '#18D8D8',
      '#A9D794',
      '#46AF78',
      '#A93F55',
      '#752de6',
      '#2176FF',
      '#33A1FD',
      '#7A918D',
      '#BAFF29',
    ];

    return {
      stroke: {
        curve: 'straight',
      },
      // Bar Chart
      series_bar: [{
        data: [],
      }],
      barChart: {
        colors,
        chart: {
          type: 'bar',
          height: 350,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            barHeight: '20px',
            borderRadius: 4,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: true,
        },
        xaxis: {
          categories: [],
        },
      },
    };
  },
  watch: {
    loading(newLoading) {
      if (this.data === 'none') {
        this.reset();
        return;
      }
      if (!newLoading) this.render();
    },
  },
  methods: {
    changeType(type) {
      this.type = type;
    },
    toHHMMSS(d) {
      /* eslint-disable */
      /* var sec_num = parseInt(this, 10); // don't forget the second param
      var hours   = Math.floor(sec_num / 3600);
      var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
      var seconds = sec_num - (hours * 3600) - (minutes * 60);

      if (hours   < 10) {hours   = "0"+hours;}
      if (minutes < 10) {minutes = "0"+minutes;}
      if (seconds < 10) {seconds = "0"+seconds;}
      return hours + ':' + minutes + ':' + seconds; */
      d = Number(d);
      var h = Math.floor(d / 3600);
      var m = Math.floor(d % 3600 / 60);
      var s = Math.floor(d % 3600 % 60);

      var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
      var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
      var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
      return hDisplay + mDisplay + sDisplay; 
      /* eslint-enable */
    },
    reset() {
      this.series_bar = [];
      if (this.$refs.barChart) this.$refs.barChart.updateSeries(this.series_pie);
    },
    render() {
      if (this.data.length > 0) {
        console.log('Chart::render', this.data);
        const barSeries = [];
        // let time = '';
        // const date = new Date(0);
        // let timeString = '';
        let seconds = 0; // Some arbitrary value
        const labels = [];
        const xaxis = [];
        console.log('Bar:: Show me everything: ', this.data[0]);
        this.data[0].details.forEach((insight) => {
          if (insight.timeSpent) {
            // time = this.toHHMMSS(insight.timeSpent.raw);
            // date.setSeconds(insight.timeSpent.raw); // specify value for SECONDS here
            // timeString = date.toISOString().substring(11, 19);
            // timeString = date.toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*/, "$1")
            seconds = this.toHHMMSS(insight.timeSpent.raw);
            const date = new Date(seconds * 1000); // multiply by 1000 because Date() requires miliseconds
            const timeStr = date.toTimeString().split(' ')[0];
            /* barSeries.push({
              name: insight.name,
              data: insight.timeSpent.raw,
            }); */
            barSeries.push(`${seconds} teste`);
            xaxis.push(insight.name);
            labels.push(insight.name);
          }
        });
        console.log('Bar series: ', barSeries);
        this.series_bar = barSeries;
        this.$refs.barChart.updateSeries([{
          name: 'Horas',
          data: this.series_bar,
        }]);
        // this.barChart.xaxis.categories = xaxis;
        this.$refs.barChart.updateOptions({
          tooltip: {
            // theme: 'dark',
            x: {
              show: true,
              format: 'HH mm ss',
            },
            // y: {
            //   title: {
            //     /* eslint-disable */
            //     formatter: () => {
            //       return '';
            //     },
            //     /* eslint-enable */
            //   },
            // },
          },
          xaxis: {
            categories: xaxis,
            /* labels: {
              show: false,
            }, */
          },
          /* yaxis: {
            min: 20,
            max: 100,
          }, */
        });
      }
    },
    exportData(format) {
      let chartComponent;
      if (this.type === 'line') {
        chartComponent = this.$refs.lineChart.chart;
      } else {
        chartComponent = this.$refs.pieChart.chart;
      }

      if (format === 'csv') {
        chartComponent.ctx.exports.exportToCSV({
          series: chartComponent.w.config.series,
          columnDelimiter: ';',
        });
      } else if (format === 'png') {
        chartComponent.ctx.exports.exportToPng(chartComponent.ctx);
      } else {
        chartComponent.ctx.exports.exportToSVG(chartComponent.ctx);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.chart {
  flex: 1 0 100%;
  &.chart-center {
    margin-top: auto;
    margin-bottom: auto;
  }
}
.chart-opt::v-deep {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  min-height: 40px;
}
.chart-opt span:first-of-type {
  margin-right: auto;
  font-weight: bold;
}
.icon_opt {
  background-color: #f7f7f7;
  padding: 4px;
  border-radius: 5px;
  font-size: 32px;
  max-width: 42px;
  cursor: pointer;
  color: var(--gray-font-color);
  transition: 0.5s;
}
.icon_opt.active {
  color: var(--clr-yup-purple);
}
.icon_opt:hover {
  background-color: #e7e7e7;
}
.darkmode .icon_opt {
  background-color: var(--background-3);
}

::v-deep .btn {
  position: relative;
  font-size: 1.5rem;
  padding: 0.5rem;

  &.calendar {
    input {
      cursor: pointer;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
    }
  }
}
</style>
