<template>
  <div class="chart-wrapper">
    <div class="chart-opt">
      <span>{{ title }}</span>
    </div>
    <div class="d-flex h-500">
      <!-- <div v-show="loading" class="qt-block-ui" /> -->
      <div v-show="loading" class="loading min-h-300">
        <LoadingAnim />
      </div>
      <div v-show="!loading" class="chart chart-center">
        <LottieAnimNoData v-show="this.data === 'none'" />
        <div v-show="this.data !== 'none'">
          <apexchart
            class="radial-semi-chart"
            ref="radialSemiChart"
            height="500"
            :options="radialSemi"
            :series="series_radial"
          ></apexchart>
          <p class="text-center mt-4">Total de negociações: {{totalRadial}}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';
import LottieAnimNoData from '@/components/rebranding/animLottie/NoData.vue';

export default {
  name: 'Chart',
  components: {
    LoadingAnim,
    LottieAnimNoData,
  },
  props: {
    title: {
      type: String,
    },
    data: {
      type: [Array, String],
    },
    loading: {
      type: Boolean,
    },
    options: {
      type: Boolean,
    },
  },
  data() {
    const colors = [
      '#F3B415',
      '#F27036',
      '#663F59',
      '#6A6E94',
      '#4E88B4',
      '#00A7C6',
      '#18D8D8',
      '#A9D794',
      '#46AF78',
      '#A93F55',
      '#752de6',
      '#2176FF',
      '#33A1FD',
      '#7A918D',
      '#BAFF29',
    ];

    return {
      stroke: {
        curve: 'straight',
      },
      totalRadial: 0,
      series_radial: [0],
      radialSemi: {
        chart: {
          type: 'radialBar',
          offsetY: -20,
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: '#fff',
              strokeWidth: '97%',
              margin: 5, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                color: '#999',
                opacity: 1,
                blur: 2,
              },
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                offsetY: -2,
                fontSize: '22px',
              },
            },
          },
        },
        grid: {
          padding: {
            top: -10,
          },
        },
        fill: {
          colors: ['#16CD08'],
          /* type: 'gradient',
          gradient: {
            shade: 'light',
            shadeIntensity: 0.4,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 53, 91],
          }, */
        },
        labels: ['Concluídos'],
      },
    };
  },
  watch: {
    loading(newLoading) {
      if (this.data === 'none') {
        this.reset();
        return;
      }
      if (!newLoading) this.render();
    },
  },
  methods: {
    changeType(type) {
      this.type = type;
    },
    reset() {
      this.series_radial = [0];
      if (this.$refs.radialSemiChart) this.$refs.radialSemiChart.updateSeries(this.series_radial);
      this.totalRadial = 0;
    },
    render() {
      if (this.data.length > 0) {
        console.log('Chart::render', this.data);
        const radialSemiSeries = [];
        console.log('Radial:: Show me everything: ', this.data[0]);
        this.data[0].forEach((insight) => {
          radialSemiSeries.push(insight);
        });
        this.series_radial = [radialSemiSeries[0]];
        this.$refs.radialSemiChart.updateSeries(radialSemiSeries);
        this.totalRadial = this.data[1].total;
      }
    },
    exportData(format) {
      let chartComponent;
      if (this.type === 'line') {
        chartComponent = this.$refs.lineChart.chart;
      } else {
        chartComponent = this.$refs.pieChart.chart;
      }

      if (format === 'csv') {
        chartComponent.ctx.exports.exportToCSV({
          series: chartComponent.w.config.series,
          columnDelimiter: ';',
        });
      } else if (format === 'png') {
        chartComponent.ctx.exports.exportToPng(chartComponent.ctx);
      } else {
        chartComponent.ctx.exports.exportToSVG(chartComponent.ctx);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.chart {
  flex: 1 0 100%;
  &.chart-center {
    margin-top: auto;
    margin-bottom: auto;
  }
}
.chart-opt::v-deep {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  min-height: 40px;
}
.chart-opt span:first-of-type {
  margin-right: auto;
  font-weight: bold;
}
.icon_opt {
  background-color: #f7f7f7;
  padding: 4px;
  border-radius: 5px;
  font-size: 32px;
  max-width: 42px;
  cursor: pointer;
  color: var(--gray-font-color);
  transition: 0.5s;
}
.icon_opt.active {
  color: var(--clr-yup-purple);
}
.icon_opt:hover {
  background-color: #e7e7e7;
}
.darkmode .icon_opt {
  background-color: var(--background-3);
}

::v-deep .btn {
  position: relative;
  font-size: 1.5rem;
  padding: 0.5rem;

  &.calendar {
    input {
      cursor: pointer;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
    }
  }
}
</style>
