<template>
  <div class="content">
    <PageHeader :breadcrumbLinks="breadcrumbLinks" title="Pipelines" :subtitle="$tc('app.report', 2)" />
    <!-- Filter task (sidebar) -->
    <b-sidebar id="sidebar-right-filter-task" ref="showTaskSide" :title="$t('catalog.create')" no-header right backdrop shadow>
      <div class="col sidebar-header">
        <div class="header-info-wrapper">
          <p class="sidebar-title">{{$tc('app.filter', 2)}}</p>
          <p class="sidebar-desc">{{$t('workflow.workspace.board.card.filter-desc')}}</p>
        </div>
        <span v-b-toggle.sidebar-right-filter-task class="material-symbols-outlined sidebar-close">close</span>
      </div>
      <div class="col sidebar-item date-column">
        <span class="label-sidebar-item">Data de vencimento</span>
        <Calendar id="icon" ref="calendarDate" v-model="options.date" :value="options.date" @input="handleCalendar" selectionMode="range" :manualInput="false" dateFormat="dd/mm/yy" :showTime="false" :showIcon="true" />
        <!-- <code>{{taskDate}}</code> -->
      </div>
      <!-- <div class="col title-column">
        <div class="form-wrapper">
          <form @submit.prevent="search" class="form-group col px-0 mb-0">
            <div class="input-group">
              <input
                :placeholder="`${$t('app.search')}...`"
                type="text"
                class="form-control"
                :v-model="searchQuery"
                ref="searchInput"
              />
              <div class="input-group-prepend">
                <button @click="search" type="button" class="btn icon_btn m-0">
                  <span class="material-symbols-outlined"> search </span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div> -->
      <!-- <div class="col btn-wrapper">
        <button class="btn btn-outline-primary mt-3" v-b-toggle.sidebar-right-filter-task>{{$t('app.cancel')}}</button>
        <button class="btn btn-primary mt-3 ml-auto">{{$t('app.save')}}</button>
      </div> -->
    </b-sidebar>
    <!--/Filter task (sidebar) -->
    <div class="page-content container-fluid">
      <div class="title-wrapper">
        <div class="pipeline-info"><h2>{{$tc('app.report', 1)}}</h2></div>
        <div class="pipeline-actions">
          <button class="btn" v-b-toggle.sidebar-right-filter-task><span class="material-symbols-outlined">filter_list</span></button>
        </div>
      </div>
      <div class="row row-cols-1 row-cols-md-4 card-chart-row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <img class="img-report" src="@/assets/lead.gif" />
              <h5 class="card-title">{{dealsReport.created}}</h5>
              <p class="card-text">Leads</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card">
            <div class="card-body">
              <img class="img-report" src="@/assets/convertions.gif" />
              <h5 class="card-title">{{dealsReport.conversions}}</h5>
              <p class="card-text">Conversões</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card">
            <div class="card-body">
              <img class="img-report" src="@/assets/sold.gif" />
              <h5 class="card-title">{{dealsReport.sold | currency}}</h5>
              <p class="card-text">Valor total de vendas</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card">
            <div class="card-body">
              <img class="img-report" src="@/assets/lost.gif" />
              <h5 class="card-title">{{dealsReport.lost | currency}}</h5>
              <p class="card-text">Oportunidades Perdidas</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Charts -->
      <div class="row chart-row">
        <div class="col-12">
          <chart
            ref="deals"
            title="Leads por status"
            :data="dealsReport.leads"
            :loading="loadingDeals.leads" />
        </div>
        <div class="col-6">
          <chart
            ref="deals"
            format="pie"
            title="Produtos anexados"
            :data="dealsReport.attatched"
            :loading="loadingDeals.attatched" />
        </div>
        <div class="col-6">
          <radial-chart
            ref="deals"
            title="Atividades concluídas"
            :data="dealsReport.finished"
            :loading="loadingDeals.finished" />
        </div>
        <div class="col-6">
          <bar-chart
            ref="deals"
            title="Tempo médio de negociação"
            :bar="true"
            :data="dealsReport.averageTime"
            :loading="loadingDeals.averageTime" />
        </div>
      </div>
      <!--/Charts -->
    </div>
  </div>
</template>

<script>
import 'primevue/resources/themes/saga-blue/theme.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'primevue/resources/primevue.min.css';
import bootstrap from 'bootstrap'; // Collapse horizontal
// @ is an alias to /src
import axios from '@/plugins/axios';
import moment from 'moment';
import PageHeader from '@/components/rebranding/PageHeader.vue';
import Calendar from 'primevue/calendar';
import Chart from '@/components/rebranding/Chart.vue';
import RadialChart from '@/components/rebranding/apexcharts/RadialChart.vue';
import BarChart from '@/components/rebranding/apexcharts/BarChart.vue';
// import Dropdown from '@/components/Dropdown';
// import Modal from '@/components/Modal';
// import Checkbox from '@/components/rebranding/Checkbox.vue';
// import Tab from '@/directives/Tab';
// import SelectInput from '@/components/SelectInput.vue';

const webApi = axios();

export default {
  name: 'PipelineDashboard',
  components: {
    PageHeader,
    Calendar,
    Chart,
    RadialChart,
    BarChart,
    // Dropdown,
    // Modal,
    // SelectInput,
    // Checkbox,
    // Card,
  },
  /* directives: {
    Tab,
  }, */
  data() {
    return {
      breadcrumbLinks: {
        1: '/sms/reports/stat',
        2: '/sms/reports/stat',
      },

      filter: {
        carrier: [],
        status: [],
        from: '',
        to: '',
        segments: '',
        campaign_id: '',
      },
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        date: '',
        startDate: moment().format('YYYY-MM-DD 00:00:00'),
        endDate: moment().format('YYYY-MM-DD 23:59:59'),
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      form: {
        category: 'sms',
        date: {
          gte: moment().format('YYYY-MM-DD'),
          lte: moment().format('YYYY-MM-DD 23:59:59'),
        },
        filters: {
          carrier: [],
          status: [],
          from: '',
          to: '',
          segments: '',
          campaign_id: '',
        },
      },
      campaigns: [],
      loadingDeals: {
        leads: true,
        attatched: true,
        finished: true,
        averageTime: true,
      },
      dealsReport: {
        created: 0,
        conversions: 0,
        sold: 0,
        lost: 0,
        leads: [],
        attatched: [],
        finished: [],
        averageTime: [],
      },
    };
  },
  created() {
    this.fetchAll();
  },
  methods: {
    showFilter() {
      this.$refs['filter-modal'].toggle();
    },
    reload() {
      this.$refs.view.fetch();
    },
    applyFilter() {
      this.form.filters = this.filter;
      this.$nextTick(() => {
        this.reload();
      });
    },
    changeCampaign(route, campaignId) {
      this.filter.campaign_id = campaignId;
      this.$router.push(route);
      this.applyFilter();
    },
    cleanFilter() {
      this.filter = {
        carrier: [],
        status: [],
        from: '',
        to: '',
        segments: '',
        campaign_id: '',
      };
      this.form.filter = {};
      this.reload();
    },
    changeDate(date) {
      if (date) {
        this.startDate = date[0];
        this.endDate = moment(date[1]).format('YYYY-MM-DD 23:59:59');

        this.$nextTick(() => {
          this.reload();
        });
      }
    },
    exportData(format) {
      this.$refs.view.exportData(format);
    },
    fetchCampaigns() {
      this.$store.dispatch('fetchSmsCampaigns').then((response) => {
        this.campaigns = response;
      });
    },
    handleCalendar() {
      if (this.options.date[1] !== null) {
        this.options.startDate = moment(this.options.date[0], 'DD/MM/YYYY ').format('YYYY-MM-DD 00:00:00');
        this.options.endDate = moment(this.options.date[1], 'DD/MM/YYYY ').format('YYYY-MM-DD 23:59:59');
        this.fetchAll();
      }
      // const date = this.currentTask.due_date.toISOString();
      // setTimeout(() => {
      //   // console.log('Calendar: ', this.$refs.calendarDate._computedWatchers.inputFieldValue.value);
      //   // this.taskDate = this.$refs.calendarDate._computedWatchers.inputFieldValue.value;
      //   console.log('Date: ', date);
      // }, 500);
    },
    fetchDealCount() {
      // console.log('time: ', this.startDate);
      return new Promise((resolve, reject) => {
        webApi.post('/pipelines/reports/deals/count', { start_date: this.options.startDate, end_date: this.options.endDate }).then(
          (response) => {
            console.log('fetchDealCount: ', response);
            this.dealsReport.created = response.data;
            return resolve(response);
          },
          (error) => {
            console.log('fetchDealCount: ', error);
            return reject(error);
          },
        );
      });
    },
    fetchDealResult() {
      return new Promise((resolve, reject) => {
        webApi.post('/pipelines/reports/deals/result', { start_date: this.options.startDate, end_date: this.options.endDate }).then(
          (response) => {
            console.log('fetchDealResult: ', response);
            this.dealsReport.conversions = response.data;
            return resolve(response);
          },
          (error) => {
            console.log('fetchDealResult: ', error);
            return reject(error);
          },
        );
      });
    },
    fetchDealSold() {
      return new Promise((resolve, reject) => {
        webApi.post('/pipelines/reports/deals/sold', {
          start_date: this.options.startDate,
          end_date: this.options.endDate,
          type: 'done',
        }).then(
          (response) => {
            console.log('fetchDealSold: ', response);
            this.dealsReport.sold = response.data;
            return resolve(response);
          },
          (error) => {
            console.log('fetchDealSold: ', error);
            return reject(error);
          },
        );
      });
    },
    fetchDealLost() {
      return new Promise((resolve, reject) => {
        webApi.post('/pipelines/reports/deals/sold', {
          start_date: this.options.startDate,
          end_date: this.options.endDate,
          type: 'canceled',
        }).then(
          (response) => {
            console.log('fetchDealLost: ', response);
            this.dealsReport.lost = response.data;
            return resolve(response);
          },
          (error) => {
            console.log('fetchDealLost: ', error);
            return reject(error);
          },
        );
      });
    },
    fetchDealLeads() {
      return new Promise((resolve, reject) => {
        // this.dealsReport.leads = [];
        this.loadingDeals.leads = true;
        webApi.post('/pipelines/reports/deals/insight', {
          start_date: this.options.startDate,
          end_date: this.options.endDate,
        }).then(
          (response) => {
            console.log('fetchDealLeads: ', response);
            this.dealsReport.leads = response.data;
            return resolve(response);
          },
          (error) => {
            console.log('fetchDealLeads: ', error);
            return reject(error);
          },
        ).finally(() => {
          this.loadingDeals.leads = false;
        });
      });
    },
    fetchDealAttatched() {
      return new Promise((resolve, reject) => {
        this.loadingDeals.attatched = true;
        webApi.post('/pipelines/reports/deals/attatched', {
          start_date: this.options.startDate,
          end_date: this.options.endDate,
        }).then(
          (response) => {
            console.log('fetchDealAttatched: ', response);
            this.dealsReport.attatched = response.data;
            return resolve(response);
          },
          (error) => {
            console.log('fetchDealAttatched: ', error);
            return reject(error);
          },
        ).finally(() => {
          this.loadingDeals.attatched = false;
        });
      });
    },
    fetchDealfinished() {
      // console.log('time: ', this.startDate);
      return new Promise((resolve, reject) => {
        this.loadingDeals.finished = true;
        webApi.post('/pipelines/reports/deals/finished', { start_date: this.options.startDate, end_date: this.options.endDate }).then(
          (response) => {
            console.log('fetchDealfinished: ', response);
            this.dealsReport.finished = response.data;
            return resolve(response);
          },
          (error) => {
            console.log('fetchDealfinished: ', error);
            return reject(error);
          },
        ).finally(() => {
          this.loadingDeals.finished = false;
        });
      });
    },
    fetchDealAverageTime() {
      // console.log('time: ', this.startDate);
      return new Promise((resolve, reject) => {
        this.loadingDeals.averageTime = true;
        webApi.post('/pipelines/reports/deals/averageTime', { start_date: this.options.startDate, end_date: this.options.endDate }).then(
          (response) => {
            console.log('fetchDealAverageTime: ', response);
            this.dealsReport.averageTime = response.data;
            return resolve(response);
          },
          (error) => {
            console.log('fetchDealAverageTime: ', error);
            return reject(error);
          },
        ).finally(() => {
          this.loadingDeals.averageTime = false;
        });
      });
    },
    async fetchAll() {
      let myPromise = Promise;
      myPromise = Promise.all([this.fetchDealCount(), this.fetchDealResult(), this.fetchDealSold(), this.fetchDealLost(), this.fetchDealLeads(), this.fetchDealAttatched(), this.fetchDealfinished(), this.fetchDealAverageTime()]);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~primevue/resources/themes/saga-blue/theme.css';
@import '~primevue/resources/primevue.min.css';

.table-responsive {
  min-height: 150px;
}

/* Rebranding */
::v-deep {
  .chart-wrapper {
    box-shadow: 0px 0px 3px #E5E5E5;
    padding: 1.25rem;
  }
}

.card-chart-row {
  .card {
    box-shadow: 0px 0px 3px #E5E5E5;
    height: 200px;
    .card-title {
      font-size: 32px;
    }
  }
  .img-report {
    width: 71px;
    height: 71px;
    margin-bottom: 1rem;
  }
}
.chart-row {
  gap: 1.75rem 0;
}
.card.card-tabs .card-header .nav.nav-tabs {
  width: 100%;
  padding: 0 1rem;
  gap: 2.5rem;
}
.card.card-tabs .card-header .nav.nav-tabs li a {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 1.4rem 0.7rem 1.3rem;
  color: var(--clr-light-2);
}
.nav.nav-tabs .nav-item .nav-link.router-link-exact-active {
  color: var(--clr-yup-purple) !important;
  border-bottom: solid 2px var(--clr-yup-purple);
}

.nav.nav-tabs .nav-item .nav-link.router-link-exact-active i {
  color: var(--clr-yup-purple) !important;
}

.card.card-tabs .card-header .nav.nav-tabs li a i {
  font-size: 24px;
}

.nav-tabs .nav-item {
  margin-bottom: -2px;
}
.nav-tabs {
  border-bottom: 2px solid rgba(210, 221, 234, 0.5);
}

.rebranding .card .opts_card i {
  font-size: 22px;
  transition: 0.5s;
  padding: 5px;
}

.rebranding .card .opts_card i:hover {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.calendar_form {
  position: absolute;
  right: 70px;
  width: 38px;
  opacity: 0;
}

.calendar_form:hover ~ .calendar_icon,
.calendar_form:focus-within ~ .calendar_icon {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.calendar_form input {
  cursor: pointer;
  width: 38px;
}

/* Card-tabs mobile */
@media (max-width: 767.98px) {
  .card-tabs .nav-link span:last-of-type:not(.card-tabs .nav-link.router-link-active span) {
    display: none;
  }
  .card.card-tabs .card-header .nav.nav-tabs li a {
    transform: scale(1.1);
    height: 40px;
    padding: 20px 5px !important;
    padding-top: 30px !important;
  }
  .card.card-tabs .card-header .nav.nav-tabs {
    justify-content: space-between;
    gap: 5px !important;
  }
  .rebranding .card .opts_card {
    justify-content: flex-end;
    margin-left: unset !important;
    width: 100%;
    padding-top: 10px;
  }
  .rebranding .card .opts_card span {
    font-size: 26px;
    padding-top: 0;
  }
}
/* PrimeVue */
::v-deep {
  .pi.pi-calendar {
    font-family: 'Material Symbols Outlined';
  }
  .b-sidebar-body {
    .p-calendar {
      width: 100%;
      .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
        margin-right: 0;
      }
    }
    .p-calendar-w-btn .p-inputtext {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    .p-calendar-w-btn .p-datepicker-trigger {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    .p-inputtext {
      border-color: var(--form-border) !important;
      border-right: none;
    }
    .p-button {
      background: transparent;
      border: 1px solid var(--form-border);
      border-left: none;
      color: var(--gray-font-color-4);
      &:hover, &:focus {
        background: unset;
        border: 1px solid unset;
      }
    }
  }
  .pi-calendar::before {
    font-size: 24px;
    content: "\e935";
  }
}
/* Sidebar add prod */
::v-deep .b-sidebar {
  background-color: var(--background-2) !important;
  width: 525px;
  small {
    color: var(--gray-font-color);
  }

  .sidebar-header {
    display: flex;
    align-items: center;
    padding-top: .5rem;
    margin-bottom: 2rem;
    .sidebar-title {
      color: var(--foreground);
      font-size: 1.25rem;
    }
    .sidebar-close {
      position: absolute;
      top: 10px;
      right: 10px;
    }
    p {
      display: block;
      font-size: 1rem;
      margin-bottom: 1px;
    }
    p, span.material-symbols-outlined {
      color: var(--gray-font-color-2);
    }
  }
  .title-column {
    margin-bottom: 2rem;
    .title-task {
      display: flex;
      align-items: center;
      margin-bottom: .5rem;
      * {
        font-size: 1.625rem;
      }
      .not-editing {
        margin-right: 1rem;
      }
    }
  }
  .responsable-column {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: .5rem;
    margin-bottom: 2.5rem;
    p {
      font-size: 1rem;
      margin-right: .5rem;
      margin-bottom: 0;
    }
    .material-symbols-outlined {
      color: var(--gray-font-color);
    }
    img {
      border-radius: 50%;
      width: 40px;
      height: 40px;
      &:last-of-type {
        margin-right: .5rem;
      }
    }
  }
  .profile-wrapper {
    display: flex;
    .profile {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      flex-shrink: 0;
      overflow: hidden;
      box-shadow: 0 0 0 4px #fff;
      pointer-events: none;
      color: #fff;
      svg {
        width: 20px;
        height: 20px;
      }

      &.faded-icon {
        background-color: var(--c-grey-100);
        color: var(--c-grey-400);
      }

      &.filled-icon {
        background-color: var(--c-blue-500);
        color: #fff;
      }
    }
  }
  .assign-user-column {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .label-sidebar-item {
      width: fit-content !important;
      margin-right: .5rem;
    }
    svg {
      color: var(--gray-font-color);
      cursor: pointer;
      margin-top: 0 !important;
    }
  }
  .description-column {
    > p {
      font-size: 1rem;
      margin-bottom: .5rem;
    }
    & p:last-of-type {
      font-size: .875rem;
      color: var(--gray-font-color);
    }
    /* textarea {
      margin-bottom: 1px;
    } */
  }
  .btn-wrapper {
    display: flex;
    align-items: center;
  }
  .b-sidebar-body {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1.5rem;

    .sidebar-item {
      margin-bottom: 3rem;
      .label-sidebar-item {
        display: block;
        width: 100%;
        font-weight: 400;
        color: var(--foreground);
        // margin-bottom: 1.5rem;
      }
      img.profile-img {
        border-radius: 50%;
        width: 42px;
        height: 42px;
        margin-right: .5rem;
      }
      .label-sidebar-item ~ * {
        margin-top: 1rem;
      }
    }
  }
  header {
    >strong {
      order: -1;
    }
  }
  .title-column {
    p {
      color: var(--gray-font-color);
    }
  }
  textarea, select, .form-control {
    border-radius: 8px;
  }

  .input-group > .form-control:not(:last-child) {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .input-group-prepend:not(:first-child) > .btn {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .create-custom-field {
    display: flex;
    flex-wrap: wrap;

    button {
      border-radius: 8px;
      width: fit-content;
    }

    .input-group {
      display: flex;
      margin-bottom: 1.5rem;
      label {
        display: flex;
        align-items: center;
        gap: .5rem;
      }
      label:not(label.control) {
        width: 100%;
      }
      label.control ~ span {
        font-weight: 400;
        font-size: .875rem !important;
        margin-left: .5rem;
        color: var(--gray-font-color-6);
      }
    }
    .material-symbols-outlined {
      color: var(--gray-font-color);
    }
    /* .title-group {
      font-weight: 400;
      color: var(--gray-font-color-6);
      margin-bottom: 1rem;
    } */
    /* .custom-field-list {
      width: 100%;
      li {
        margin-bottom: .5rem;
        color: var(--gray-font-color-2);
        font-weight: 400;
      }
    } */
  }
}
.title-wrapper {
  display: flex;
  > h2 {
    font-size: 1.25rem;
  }
}
.pipeline-actions {
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: .5rem;
    .btn {
      width: fit-content;
      height: fit-content;
      padding: 0;
    }
    .settings-icon {
      color: var(--gray-font-color-4);
    }
  }
</style>
